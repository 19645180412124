/* eslint-disable no-eval */
import { useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { FiEdit } from "react-icons/fi";
import {
  CREATE_MDR_REQUEST,
  GET_MDR_REQUESTS,
} from "../../../../../../Qurries";
import { toast } from "react-toastify";
import RateChargeModal from "../../../../../../components/Modal/RateChargeModal";
import RequestTab from "../../RequestTab/RequestTab";
import SingleSchoolRangeModal from "../SingleSchoolRangeModal";
import Modal from "../../../../../../components/Modal/Modal";
import { resultFilter } from "../../../../../../utils/filterObject";
import { dashboardContext } from "../../../../Dashboard";
import Select from "react-select";
import { CustomDropdownIndicator } from "../../../../Settlement/Settlement";

const _ = require("lodash");
export const addFromValues = (arr: any) => {
  let previousUpto = 0;
  return arr.map((item: any) => {
    const newItem = { ...item, from: previousUpto };
    previousUpto = item.upto !== null ? item.upto : previousUpto;
    return newItem;
  });
};

const SchoolPaymentMode = ({
  mode,
  edit,
  handleFinalRatesChange,
  handleCommissionChange,
  indexOfPaymetMode,
  paymentModes,
  setPaymentModes,
  setRequestButtonStatus,
}: any) => {
  const updatedRangeCharge = useMemo(() => {
    if (!mode?.range_charge) return [];

    return mode.range_charge.map((range: any) => {
      const { base_charge, base_charge_type, charge, charge_type } = range;

      const isFlat = (type: string) => type.toUpperCase() === "FLAT";
      const isPercent = (type: string) => type.toUpperCase() === "PERCENT";

      const baseChargeFormatted = isFlat(base_charge_type)
        ? `₹${base_charge.toFixed(2)}`
        : `${base_charge.toFixed(2)}%`;

      const chargeFormatted = isFlat(charge_type)
        ? `₹${charge.toFixed(2)}`
        : `${charge.toFixed(2)}%`;

      const commission =
        isFlat(charge_type) && isFlat(base_charge_type)
          ? `₹${(charge - base_charge).toFixed(2)}`
          : isPercent(charge_type) && isPercent(base_charge_type)
            ? `${(charge - base_charge).toFixed(2)}%`
            : `${chargeFormatted} - ${baseChargeFormatted}`;

      return { ...range, commission };
    });
  }, [mode?.range_charge]);

  const [rangeCharge, setRangeCharge] = useState(() => updatedRangeCharge);

  useEffect(() => {
    if (!_.isEqual(rangeCharge, updatedRangeCharge)) {
      setRangeCharge(updatedRangeCharge);
    }
  }, [updatedRangeCharge]);

  const handleFinalRateChange2 = useCallback(
    (finalRate: number, index: number) => {
      setRangeCharge((prevRangeCharge: any) => {
        if (prevRangeCharge[index]?.charge === finalRate)
          return prevRangeCharge;

        const newRangeCharge = prevRangeCharge.map((item: any, i: number) => {
          if (i !== index) return item;

          const formattedFinalRate =
            item.charge_type === "FLAT"
              ? `₹${finalRate.toFixed(2)}`
              : `${finalRate.toFixed(2)}%`;

          const formattedBaseCharge =
            item.base_charge_type === "FLAT"
              ? `₹${item.base_charge.toFixed(2)}`
              : `${item.base_charge.toFixed(2)}%`;

          const commission =
            item.base_charge_type === item.charge_type
              ? item.charge_type === "FLAT"
                ? `₹${(finalRate - item.base_charge).toFixed(2)}`
                : `${(finalRate - item.base_charge).toFixed(2)}%`
              : `${formattedFinalRate} - ${formattedBaseCharge}`;

          return {
            ...item,
            charge: finalRate,
            commission,
          };
        });

        setPaymentModes((prevPaymentModes: any) => {
          if (
            _.isEqual(
              prevPaymentModes[indexOfPaymetMode]?.range_charge,
              newRangeCharge,
            )
          ) {
            return prevPaymentModes;
          }
          const updatedPaymentsMode = [...prevPaymentModes];
          updatedPaymentsMode[indexOfPaymetMode] = {
            ...updatedPaymentsMode[indexOfPaymetMode],
            range_charge: newRangeCharge,
          };
          return updatedPaymentsMode;
        });

        return newRangeCharge;
      });
    },
    [setPaymentModes, indexOfPaymetMode],
  );

  const debouncedFinalRateChange = useMemo(
    () =>
      _.debounce((finalRate: number, index: number) => {
        handleFinalRateChange2(finalRate, index);
      }, 80),
    [handleFinalRateChange2],
  );

  const preventNegativeValues = useCallback((e: any) => {
    if (["+", "-"].includes(e.key)) e.preventDefault();
  }, []);

  const filterdArray = useMemo(
    () =>
      addFromValues(
        _.sortBy(rangeCharge, (item: any) =>
          item.upto === null ? Infinity : item.upto,
        ),
      ),
    [rangeCharge],
  );

  const modes = useMemo(
    () => ["UPI", "CORPORATE CARDS", "INTERNATIONAL PG"],
    [],
  );

  return filterdArray.map((range: any, rangeIndex: number) => {
    return (
      <div
        className={`grid gap-x-2 ${edit ? "grid-cols-6" : "grid-cols-5"}`}
        key={rangeIndex}
      >
        <div>
          <p className="text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} <br />
            {!modes.includes(mode?.platform_type) && (
              <span className="text-[8px]">( {mode?.payment_mode} )</span>
            )}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.upto
              ? `${range?.from > 0 ? range?.from : "<="} ${
                  range?.upto >= range?.from && range?.from !== 0
                    ? "-" + range?.upto
                    : range?.upto
                }`
              : `>=${range?.from}`}
          </p>
        </div>
        <div>
          <p className="text-[#1B163B] text-xs font-normal">
            {range?.base_charge_type === "FLAT"
              ? "₹" + range?.base_charge.toFixed(2)
              : range?.base_charge.toFixed(2) + "%"}
          </p>
        </div>
        <div>
          <input
            type="text"
            disabled
            className="w-[100%] bg-transparent text-xs font-normal text-[#1B163B] px-2 py-1.5 text-center"
            value={range?.commission}
          />
        </div>
        <div>
          {edit ? (
            <input
              onKeyDown={preventNegativeValues}
              disabled={!edit}
              min={0}
              onChange={(e) => {
                debouncedFinalRateChange(Number(e.target.value), rangeIndex);
              }}
              className={`focus:outline-none transition-all duration-150 ease-in-out text-xs font-normal text-[#1B163B] px-2 py-1.5 w-full lg:max-w-[80%] rounded-md text-center ${
                edit ? "bg-white" : "bg-transparent"
              }`}
              type="number"
              value={range?.charge}
            />
          ) : (
            <input
              type="text"
              disabled
              className="w-full lg:max-w-[80%] bg-transparent text-xs font-normal text-[#1B163B] px-2 py-1.5 text-center"
              value={
                !edit && range?.charge_type === "FLAT"
                  ? `₹${range?.charge.toFixed(2)}`
                  : `${range?.charge.toFixed(2)}%`
              }
            />
          )}
        </div>
        <div>
          {edit && (
            <Select
              placeholder="Select Type"
              className="font-normal m-0 capitalize"
              options={[
                { label: "PERCENT", value: "PERCENT" },
                { label: "FLAT", value: "FLAT" },
              ].map((type) => {
                return {
                  label: type.label,
                  value: type.value,
                };
              })}
              value={
                range?.charge_type
                  ? { label: range.charge_type, value: range.charge_type }
                  : null
              }
              onChange={(e: any) => {
                const updatedRangeCharge = [...rangeCharge];
                updatedRangeCharge[rangeIndex] = {
                  ...updatedRangeCharge[rangeIndex],
                  charge_type: e.value,
                };
                setRangeCharge(updatedRangeCharge);
                setPaymentModes((prevPaymentModes: any) => {
                  const updatedPaymentsMode = [...prevPaymentModes];
                  updatedPaymentsMode[indexOfPaymetMode] = {
                    ...updatedPaymentsMode[indexOfPaymetMode],
                    range_charge: updatedRangeCharge,
                  };
                  return updatedPaymentsMode;
                });
              }}
              onKeyDown={(e: any) => {
                e.preventDefault();
              }}
              components={{
                DropdownIndicator: CustomDropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  backgroundColor: "#F6F8FA",
                  border: "1px solid #1B163B",
                  borderRadius: "6px",

                  minHeight: "15px",
                  margin: "0px",
                  color: "#6687FF",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  padding: "4px",
                  paddingLeft: "0.5rem",
                  fontSize: "12px",
                }),
                input: (provided: any) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  color: "#000",
                  "::placeholder": {
                    backgroundColor: "#YourDesiredColor",
                    opacity: 1,
                  },
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: "red",
                  }),
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "10px",
                }),
              }}
            />
          )}
        </div>
      </div>
    );
  });
};

function RateTab({
  data,
  setOpen,
  PENDING_REQUEST,
  setPaymentRange,
  paymentRange,
  isBulk,
  schoolId,
  setfinalPaymentInfo,
  finalPaymentInfo,
  hasChanged,
  setHasChanged,
}: any) {
  const [paymentModes, setPaymentModes] = useState(data);

  const { NEW_PLATFORM_TYPE } = useContext(dashboardContext);
  const [edit, setEdit] = useState(false);
  const [addDescription, setAddDescription] = useState(false);
  const [description, setDescription] = useState("");
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openCreateNewRangeModal, setOpenCreateNewRangeModal] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [createMdr] = useMutation(CREATE_MDR_REQUEST, {
    refetchQueries: [
      {
        query: GET_MDR_REQUESTS,
      },
    ],
  });

  const handleFinalRatesChange = (
    platformIndex: number,
    rangeIndex: number,
    value: any,
  ) => {
    const updatedPlatformCharges = cloneDeep(paymentModes);
    const obj = {
      ...updatedPlatformCharges[platformIndex].range_charge[rangeIndex],
      school_mdr: value,
    };
    updatedPlatformCharges[platformIndex].range_charge[rangeIndex] = obj;
    setPaymentModes(updatedPlatformCharges);
  };

  const mdr_request_arr = paymentModes?.map((mode: any) => {
    return {
      platform_type: mode?.platform_type,
      payment_mode: mode?.payment_mode,
      range_charge: mode.range_charge.map((range: any) => {
        return {
          charge: range?.charge,
          charge_type: range?.charge_type,
          upto: range?.upto,
        };
      }),
    };
  });

  const initialArrayState = useRef(JSON.stringify(mdr_request_arr));

  useEffect(() => {
    const currentArrayState = JSON.stringify(mdr_request_arr);
    if (currentArrayState !== initialArrayState.current) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [mdr_request_arr]);

  useEffect(() => {
    if (openCreateNewRangeModal === false) {
      setPaymentRange([]);
    }
  }, [openCreateNewRangeModal]);

  const isCommissionIsNotInNegitive = paymentModes
    ?.map((d: any) =>
      d.range_charge?.map((d: any) => {
        if (d?.commission?.toString()?.slice(0, 1) === "-") {
          return true;
        }
        return false;
      }),
    )
    .flat();

  const finalRange = resultFilter(
    NEW_PLATFORM_TYPE?.map((mode: any) => {
      return {
        platform_type: mode?.label,
        payment_mode: mode?.value?.[0]?.label,
        range_charge:
          mode.value?.[0]?.range.map((range: any) => {
            return {
              charge: range?.charge,
              charge_type: range?.charge_type,
              upto: range?.upto,
            };
          }) ?? [],
      };
    }),
    items,
  ).concat(items);

  useEffect(() => {
    if (items.length > 0) {
      setfinalPaymentInfo(finalRange);
    }
  }, [items]);

  useEffect(() => {
    if (finalPaymentInfo.length > 0) {
      setPaymentModes(
        finalPaymentInfo?.map((d: any) => {
          return {
            ...d,
            range_charge: d.range_charge.map((d: any) => {
              return {
                commission: 0,
                base_charge: d.charge,
                ...d,
              };
            }),
          };
        }),
      );
    }
  }, [finalPaymentInfo]);

  return (
    <>
      <RateChargeModal open={openRequestModal} setOpen={setOpenRequestModal}>
        <RequestTab data={PENDING_REQUEST[0]} setOpen={setOpenRequestModal} />
      </RateChargeModal>
      <Modal
        open={openCreateNewRangeModal}
        setOpen={setOpenCreateNewRangeModal}
        className="max-w-3xl "
      >
        <div className="p-4 min-h-[50vh] max-h-[70vh] overflow-y-auto">
          <SingleSchoolRangeModal
            setPaymentRange={setPaymentRange}
            paymentRange={paymentRange}
            schoolId={schoolId}
            isBulk={isBulk}
            setOpen={setOpenCreateNewRangeModal}
            description={description}
            setfinalPaymentInfo={setfinalPaymentInfo}
            finalPaymentInfo={finalPaymentInfo}
            mainModal={setOpen}
            items={items}
            setItems={setItems}
          />
        </div>
      </Modal>
      <div className="px-6 py-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
        {!data?.length ? (
          <p className=" text-xs text-[#1B163B]">
            The Institute is not live yet. You can view the details once the
            institute is live.
          </p>
        ) : (
          <>
            <div className={"flex items-center justify-between"}>
              {!edit ? (
                <div
                  className={
                    "flex w-full" +
                    (!PENDING_REQUEST.length
                      ? " justify-end"
                      : " justify-between")
                  }
                >
                  {!PENDING_REQUEST.length ? (
                    <p
                      onClick={() => {
                        setEdit(!edit);
                        setAddDescription(false);
                      }}
                      className="flex cursor-pointer items-center text-[#6687FF] text-xs font-normal"
                    >
                      <FiEdit className="mr-2" />
                      Edit
                    </p>
                  ) : (
                    <>
                      <p className="text-edviron_black text-xs font-medium">
                        We have received your request.
                        <span
                          onClick={() => {
                            setOpenRequestModal(true);
                          }}
                          className="text-[10px] cursor-pointer text-[#6687FF] font-normal"
                        >
                          View Here
                        </span>
                      </p>
                      <button
                        type="submit"
                        disabled={PENDING_REQUEST.length}
                        onClick={() => {
                          setEdit(!edit);
                        }}
                        className="flex items-center text-[#6687FF] disabled:text-gray-500 text-xs font-normal"
                      >
                        <FiEdit className="mr-2" />
                        Edit
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start gap-y-2">
                    <button
                      onClick={() => setAddDescription(!addDescription)}
                      className={
                        " text-sm" +
                        (addDescription === true
                          ? " text-[#1B163B]"
                          : " text-[#6687FF]")
                      }
                    >
                      + Add Description/Reason
                    </button>
                    {/* <button
                      onClick={() => setOpenCreateNewRangeModal(true)}
                      className={
                        " text-sm" +
                        (addDescription === true
                          ? " text-[#1B163B]"
                          : " text-[#6687FF]")
                      }
                    >
                      + Add Range
                    </button> */}
                  </div>
                  <div className="flex space-x-4 items-center">
                    <button
                      onClick={() => {
                        setEdit(false);
                        setAddDescription(false);
                        setPaymentModes(
                          finalPaymentInfo.length > 0
                            ? finalPaymentInfo?.map((d: any) => {
                                return {
                                  ...d,
                                  range_charge: d.range_charge.map((d: any) => {
                                    return {
                                      commission: 0,
                                      base_charge: d.charge,
                                      ...d,
                                    };
                                  }),
                                };
                              })
                            : data,
                        );
                      }}
                      className={
                        "  py-2 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-md text-[12px] text-left  text-[#717171BF]"
                      }
                    >
                      Cancel
                    </button>
                    <button
                      // disabled={
                      //   (schoolId ? !schoolId.length : false) ||
                      //   isCommissionIsNotInNegitive?.includes(true)
                      // }
                      disabled={schoolId ? !schoolId.length : false}
                      onClick={async () => {
                        try {
                          const res = await createMdr({
                            variables: {
                              school_id: schoolId,
                              platform_charge: mdr_request_arr,
                              description: description,
                            },
                          });

                          if (res?.data?.createMdrRequest) {
                            toast.success(res?.data?.createMdrRequest);
                            setOpen(false);
                          }
                        } catch (err) {
                          setOpen(false);
                        }
                      }}
                      className={
                        "bg-edviron_black disabled:bg-gray-400 px-4 py-2 font-semibold rounded-md text-[12px]  text-white"
                      }
                    >
                      Send Request
                    </button>
                  </div>
                </>
              )}
            </div>
            {addDescription && (
              <textarea
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className="bg-white h-28 rounded-md placeholder:text-[#777777] placeholder:text-xs focus:outline-none placeholder:font-normal p-4 w-full md:max-w-[60%]"
                placeholder="enter description here"
              ></textarea>
            )}

            <div
              className={`grid gap-x-2 ${edit ? "grid-cols-6" : "grid-cols-5"}`}
            >
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  Payment Mode
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">Range</p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  Base Rates
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal text-center">
                  My Commission
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal text-center">
                  Final Rates
                </p>
              </div>
            </div>
            {paymentModes
              ?.map((mode: any, i: any) => {
                return (
                  <SchoolPaymentMode
                    mode={mode}
                    key={i}
                    edit={edit}
                    indexOfPaymetMode={i}
                    setEdit={setEdit}
                    paymentModes={paymentModes}
                    setPaymentModes={setPaymentModes}
                    //handleCommissionChange={handleChargeChange}
                    handleFinalRatesChange={handleFinalRatesChange}
                  />
                );
              })
              .slice()
              .reverse()}
          </>
        )}
      </div>
    </>
  );
}

export default RateTab;
