/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import ReactPaginate from 'react-paginate';
function Pagination({ currentPage, totalPages, onPageChange }: any) {
  const renderPaginationButtons = () => {
    const buttons = [];
    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`px-4 py-1 ${
            i === currentPage
              ? "bg-[#1B163B] text-white text-sm"
              : " text-[#1B163B] text-sm font-normal"
          }`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (totalPages > 5) {
      if (currentPage > 4) {
        buttons.unshift(
          <button
            key="prev"
            className="px-3 py-1 mx-1 text-[#1B163B] text-sm font-normal"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </button>
        );
        buttons.unshift(
          <button
            key="start"
            className="px-3 py-1 mx-1  text-[#1B163B] text-sm font-normal"
            onClick={() => onPageChange(1)}
          >
            1
          </button>
        );
        if (currentPage > 4) {
          buttons.splice(1, 0, <span key="ellipsis1">...</span>);
        }
      }

      if (currentPage + 2 < totalPages) {
        if (currentPage + 3 < totalPages) {
          buttons.splice(
            buttons.length - 1,
            0,
            <span key="ellipsis2">...</span>
          );
        }
        buttons.push(
          <button
            key="end"
            className="px-3 py-1 mx-1 text-[#1B163B] text-sm font-normal"
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
        buttons.push(
          <button
            key="next"
            className="px-3 py-1 mx-1 text-[#1B163B] text-sm font-normal"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div className="flex justify-center mt-4">{renderPaginationButtons()}</div>
  );
}

export default Pagination;
