import React, { useContext, useState } from "react";
import Transaction from "../Transaction/Transaction";
import Settlement from "../Settlement/Settlement";
import RatesAndCharges from "../RatesAndCharge/RatesAndCharges";
import { dashboardContext } from "../Dashboard";
import { Access } from "../TeamMember/TeamMembers";
import Refund from "../Refund/Refund";
import { IoIosArrowDown } from "react-icons/io";
import VendorTransaction from "./VendorTab/VendorTransaction";
import VendorSettlement from "./VendorTab/VendorSettlement";
import Disputes from "./Disputes";
import Reconciliation from "./Reconciliation";

function Payments({ menu }: any) {
  const [activeTab, setActiveTab] = useState(1);
  const [vendorActiveTab, setVendorActiveTab] = useState(11);
  const [vendorTab, setVendorTab] = useState(false);
  const { user } = useContext(dashboardContext);

  return (
    <div className="flex">
      <div
        className={
          "  transition-transform duration-200 fixed overflow-hidden   pt-10 " +
          (menu ? "translate-x-0 w-56" : " -translate-x-[100%] w-0 ")
        }
      >
        <div className=" flex flex-col  min-h-screen shrink-0">
          <button
            onClick={() => {
              setVendorTab(false);
              setActiveTab(1);
            }}
            className={
              activeTab === 1
                ? "bg-[#6687FF33] bg-opacity-20  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : "  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Transactions
          </button>
          <button
            onClick={() => {
              setVendorTab(false);

              setActiveTab(2);
            }}
            className={
              activeTab === 2
                ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Settlements
          </button>
          <button
            onClick={() => {
              setVendorTab(false);

              setActiveTab(3);
            }}
            className={
              activeTab === 3
                ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Refund
          </button>
          {user?.getUserQuery.role !== Access.FINANCE_TEAM &&
            user?.getUserQuery.role !== Access.DEVELOPER && (
              <>
                <button
                  onClick={() => {
                    setVendorTab(false);
                    setActiveTab(4);
                  }}
                  className={
                    activeTab === 4
                      ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                      : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
                  }
                >
                  Rates and Charges
                </button>
              </>
            )}
          {user?.getUserQuery.role === Access.MANAGEMENT && (
            <button
              onClick={() => {
                setVendorTab(false);
                setActiveTab(7);
              }}
              className={
                activeTab === 7
                  ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                  : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
              }
            >
              Reconciliation
            </button>
          )}
          <button
            onClick={() => {
              setVendorTab(false);
              setActiveTab(6);
            }}
            className={
              activeTab === 6
                ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Disputes
          </button>

          <div
            // onClick={() => {
            //   setActiveTab(5);
            // }}
            className={
              "cursor-pointer relative flex pr-4 items-center " +
              (activeTab === 5
                ? "bg-[#6687FF33]   font-semibold rounded-lg text-[14px] text-left  text-[#1B163B]"
                : "font-semibold rounded-lg text-[14px] text-left text-[#717171]")
            }
          >
            <span
              className="pl-[2.5rem] w-full py-1.5"
              onClick={() => {
                setVendorActiveTab(10);
                setActiveTab(5);
                setVendorTab(true);
              }}
            >
              E-Split
            </span>
            <span
              onClick={() => {
                setVendorActiveTab(10);
                setVendorTab(true);
                setActiveTab(5);
              }}
            >
              <IoIosArrowDown
                className={
                  " text-lg duration-200 transition-transform" +
                  (vendorTab === true ? " rotate-0 " : " -rotate-180")
                }
              />
            </span>
            {vendorTab === true && (
              <div className="left-0 absolute z-40 flex flex-col w-full -bottom-[70px]">
                <button
                  onClick={() => {
                    setVendorActiveTab(10);
                    setActiveTab(5);
                  }}
                  className={
                    vendorActiveTab === 10
                      ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-14 text-[#1B163B]"
                      : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-14 text-[#717171]"
                  }
                >
                  Vendor Transaction
                </button>
                <button
                  onClick={() => {
                    setVendorActiveTab(11);
                    setActiveTab(5);
                  }}
                  className={
                    vendorActiveTab === 11
                      ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-14 text-[#1B163B]"
                      : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-14 text-[#717171]"
                  }
                >
                  Vendor Settlement
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={"tab-content w-full py-4 " + (menu ? " pl-64" : " pl-2")}>
        {activeTab === 1 && <Transaction />}
        {activeTab === 2 && <Settlement />}
        {activeTab === 3 && <Refund />}
        {activeTab === 4 && <RatesAndCharges />}
        {activeTab === 6 && <Disputes />}
        {activeTab === 7 && <Reconciliation />}
        {vendorActiveTab === 10 && vendorTab === true && <VendorTransaction />}
        {vendorActiveTab === 11 && vendorTab === true && <VendorSettlement />}
      </div>
    </div>
  );
}

export default Payments;
