import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root"));
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      const contextUser = operation.getContext().user;
      if (
        window.location.pathname === "/login" &&
        path?.[0] === "getUserQuery"
      ) {
        return;
      } else if (
        window.location.pathname === "/" &&
        path?.[0] === "getUserQuery"
      ) {
        return;
      } else if (
        window.location.pathname === "/reset-password" &&
        path[0] === "getUserQuery"
      ) {
        return;
      } else {
        const token = localStorage.getItem("token");

        const config = {
          method: "post",
          url:
            process.env.REACT_APP_BACKEND_URL +
            "/main-backend/send-queryError-mail",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          data: {
            queryName: path?.[0],
            error: JSON.stringify(extensions),
            message: message,
            token,
            timestamp: new Date().toISOString(),
          },
        };

        try {
          const response = await axios.request(config);
         
        } catch (error) {
          console.log(error);
        }
        toast.error(message);
      }
    });

  if (networkError) toast.error(networkError);
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

root.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
);
