import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_SCHOOLS, TRUSTEE_REFUND_REQUESTS } from "../../../Qurries";
import { _Table } from "../../../components/Table";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { amountFormat } from "../../../utils/amountFormat";

import Select, { components } from "react-select";
import DateFilter from "./DateFilter";
import { HiMiniXMark } from "react-icons/hi2";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import { endOfDay, startOfDay } from "date-fns";
import { Link } from "react-router-dom";
function Refund() {
  const { data: refundData, loading } = useQuery(TRUSTEE_REFUND_REQUESTS);
  const [searchText, setSearchText] = useState<string>("");
  const { data } = useQuery(GET_SCHOOLS);
  const [status, setStatus] = useState<any>("");
  const [selectSchool, setSelectSchool] = useState<any>("");
  const [type, setType] = useState("");
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const schoolsList = data?.getSchoolQuery?.schools.map((school: any) => {
    return { label: school.school_name, value: school.school_name };
  });

  const [refundRequestData, setRefundRequestData] = useState<any>([]);

  useEffect(() => {
    if (refundData?.getTrusteeRefundRequest) {
      setRefundRequestData(refundData?.getTrusteeRefundRequest);
    }
  }, [refundData?.getTrusteeRefundRequest]);
  useEffect(() => {}, [refundRequestData]);

  return (
    <div className="w-full pt-2">
      {loading ? (
        <div className="min-h-[80vh] w-full flex justify-center items-center">
          <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
        </div>
      ) : (
        <>
          {refundData?.getTrusteeRefundRequest ? (
            <_Table
              pagination={true}
              heading={"Refund"}
              searchBox={
                <div className="w-full flex flex-col">
                  <div className="flex xl:!flex-row flex-col gap-2 justify-between w-full xl:items-center items-start mb-2">
                    <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                      <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                      <input
                        className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                        onFocus={(e) =>
                          (e.target.style.borderColor = "transparent")
                        }
                        type="text"
                        placeholder=" Search(Institute Name,Order ID...)"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="flex justify-end items-center flex-1 w-full max-w-[34rem]">
                      <DateFilter
                        originalData={refundData?.getTrusteeRefundRequest}
                        setRefundRequest={setRefundRequestData}
                        setType={setType}
                        type={type}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                      />
                      <div className="w-full">
                        <Select
                          className="font-normal m-0 p-2 capitalize"
                          options={[
                            { label: "INITIATED", value: "INITIATED" },
                            { label: "REJECTED", value: "REJECTED" },
                            { label: "APPROVED", value: "APPROVED" },
                            {
                              label: "DELETED BY USER",
                              value: "DELETED BY USER",
                            },
                          ].map((status: any) => {
                            return {
                              label: status.label.toLowerCase(),
                              value: status.value,
                            };
                          })}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(e: any) => {
                            setStatus(e.value);
                          }}
                          placeholder={
                            <div className="text-[#1E1B59] text-xs">Status</div>
                          }
                          value={null}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              backgroundColor: "#F6F8FA",
                              border: "1px solid #1B163B",
                              borderRadius: "6px",

                              minHeight: "15px",
                              margin: "0px",
                              color: "#6687FF",
                            }),
                            valueContainer: (provided: any) => ({
                              ...provided,
                              padding: "0px",
                              paddingLeft: "0.5rem",
                            }),
                            input: (provided: any) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              color: "#000",
                              "::placeholder": {
                                backgroundColor: "#YourDesiredColor",
                                opacity: 1,
                              },
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "red", // Set the color of the placeholder text
                              }),
                            }),
                          }}
                        />
                      </div>
                      <div className="w-full">
                        {schoolsList ? (
                          <Select
                            className="font-normal m-0  capitalize"
                            options={schoolsList}
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e: any) => {
                              setSelectSchool(e.value);
                            }}
                            placeholder={
                              <div className="text-[#1E1B59] text-xs">
                                Select Institute
                              </div>
                            }
                            value={null}
                            styles={{
                              control: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#F6F8FA",
                                border: "1px solid #1B163B",
                                borderRadius: "6px",

                                minHeight: "15px",
                                margin: "0px",
                                color: "#6687FF",
                              }),
                              valueContainer: (provided: any) => ({
                                ...provided,
                                padding: "0px",
                                paddingLeft: "0.5rem",
                              }),
                              input: (provided: any) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                color: "#000",
                                "::placeholder": {
                                  backgroundColor: "#YourDesiredColor",
                                  opacity: 1,
                                },
                                placeholder: (provided: any) => ({
                                  ...provided,
                                  color: "red", // Set the color of the placeholder text
                                }),
                              }),
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {type !== "" && (
                      <div className=" text-sm m-2  max-w-fit ">
                        <button
                          onClick={() => {
                            if (!status && !selectSchool) {
                              setRefundRequestData(
                                refundData?.getTrusteeRefundRequest,
                              );
                              setType("");
                            } else {
                              setRefundRequestData(
                                refundData?.getTrusteeRefundRequest,
                              );

                              setSelectedRange({
                                startDate: startOfDay(new Date()),
                                endDate: endOfDay(new Date()),
                                key: "selection",
                              });
                              setType("");
                            }
                          }}
                          className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                        >
                          {type} <HiMiniXMark className=" text-lg ml-1" />
                        </button>
                      </div>
                    )}
                    {selectSchool !== "" && (
                      <div className=" text-sm m-2  max-w-fit ">
                        <button
                          onClick={() => {
                            if (!status && !type) {
                              setRefundRequestData(
                                refundData?.getTrusteeRefundRequest,
                              );
                              setSelectSchool("");
                            } else {
                              setSelectSchool("");
                            }
                          }}
                          className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                        >
                          {selectSchool}{" "}
                          <HiMiniXMark className=" text-lg ml-1" />
                        </button>
                      </div>
                    )}
                    {status !== "" && (
                      <div className=" text-sm m-2  max-w-fit ">
                        <button
                          onClick={() => {
                            if (!selectSchool && !type) {
                              setRefundRequestData(
                                refundData?.getTrusteeRefundRequest,
                              );
                              setStatus("");
                            } else {
                              setStatus("");
                            }
                          }}
                          className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                        >
                          {status} <HiMiniXMark className=" text-lg ml-1" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              }
              filter={[searchText]}
              copyContent={[3, 4]}
              data={[
                [
                  "Sr.No",
                  "School Name",
                  "Refund ID",
                  "Order ID",
                  "Refund Amount",
                  "Order Amount",
                  "Transaction Amount",
                  "Status",
                  "Created Date",
                  "Updated Date",
                  "Reason",
                ],
                ...refundRequestData
                  ?.filter((d: any) => {
                    const arr = [
                      d.school_name,
                      d._id,
                      d.school_id,
                      d.order_id,
                    ].join(",");

                    return (
                      (!status || d.status === status) &&
                      (!selectSchool || d.school_name === selectSchool) &&
                      (!searchText ||
                        arr.toLowerCase().includes(searchText.toLowerCase()))
                    );
                  })
                  .map((data: any, index: any) => [
                    <div>{index + 1}</div>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                    >
                      <div className="truncate max-w-[10rem]">
                        {data.school_name}
                      </div>
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {data._id}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {data.custom_id ? data.custom_id : data.order_id}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {amountFormat(data.refund_amount)}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {amountFormat(data.order_amount)}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {amountFormat(data.transaction_amount)}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {data.status !== "APPROVED"
                        ? data.status
                        : "PROCESSED/APPROVED"}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {new Date(Number(data?.createdAt)).toLocaleString("hi")}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {new Date(Number(data?.updatedAt)).toLocaleString("hi")}
                    </Link>,
                    <Link
                      state={{
                        collect_id: data.order_id,
                        schoolId: data.school_id,
                      }}
                      to="/payments/transaction-receipt"
                      className="truncate"
                    >
                      {data?.reason || "NA"}
                    </Link>,
                  ]),
              ]}
            />
          ) : (
            <>{null}</>
          )}
        </>
      )}
    </div>
  );
}

export default Refund;
