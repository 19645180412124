import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GENERATE_API_KEY, GET_USER, SEND_OTP } from "../../../../Qurries";
// import "./api.css";
import { toast } from "react-toastify";
import { FaRegClipboard } from "react-icons/fa6";
import { dashboardContext } from "../../Dashboard";
// import { Modal } from "edviron-ui-package";
import Modal from "../../../../components/Modal/Modal";
import ConfirmationBox from "../../../../components/ConfermationBox";
import { MdContentCopy } from "react-icons/md";
import OTPInput from "../../../../components/Otp/OTPInput";

function ApiKey() {
  const [API_KEY, { loading }] = useMutation(GENERATE_API_KEY, {
    errorPolicy: "ignore",
  });
  const [sentVerificationOtp] = useMutation(SEND_OTP, {
    variables: {
      type: "api",
    },
  });

  const [currentApi, SetCurrentApi] = useState("");
  const [isAPIKey, SetIsApiKey] = useState(false);
  const [showReminder, setShowReminder] = useState(false); // State for showing the reminder
  const { user, set_user } = useContext(dashboardContext);
  const [otpConfirmationBox, setOtpConfirmationBox] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState<any>("");

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("copied successfully");
      })
      .catch((error) => {
        toast.error("Unable to copy");
      });
  };

  return (
    <div className="p-4  w-full shadow rounded-md mt-4 text-left bg-[#F6F8FA]">
      <Modal
        open={otpConfirmationBox}
        setOpen={setOtpConfirmationBox}
        className="max-w-xl"
      >
        <ConfirmationBox
          setOpen={setOtpConfirmationBox}
          funtion={async () => {
            const res = await sentVerificationOtp();
            if (res?.data?.sendOtp === true) {
              toast.success("OTP Send");
              setOtpModal(true);
            }
          }}
          confirmationText="regenerate API Key"
          ButtonText="Regenerate Key"
        />
      </Modal>
      <Modal
        hasChanged={true}
        title="Verify OTP"
        open={otpModal}
        setOpen={setOtpModal}
      >
        <OTPInput
          length={6}
          onComplete={async (value: any) => {
            const res = await API_KEY({
              variables: {
                otp: value,
              },
            });

            if (res !== null && res?.data?.createApiKey) {
              SetCurrentApi(res.data.createApiKey.key);
              SetIsApiKey(true);
              setOtpModal(false);
              toast.success("API KEY Generated");
              set_user((prev: any) => {
                return {
                  ...prev,
                  getUserQuery: {
                    ...prev.getUserQuery,
                    apiKey: res.data.createApiKey.key.slice(-4),
                  },
                };
              });
            }
          }}
        />
      </Modal>
      <p className="mb-2 text-left text-[22px]  text-[#1B163B]">API Key</p>
      <p className="mb-4 text-base text-[#1B163B]">
        Use API Key to access ERP services
      </p>
      <div className="flex items-center bg-[#EEF1F6] text-[#717171]  text-sm p-4 w-full max-w-2xl gap-x-4 shadow rounded-lg mx-auto">
        {/* API Key Display */}
        <div
          style={{
            wordBreak: "break-all",
          }}
          className=" word- px-2"
        >
          {
            // Within the ApiKey component
            isAPIKey
              ? currentApi
              : user && user.getUserQuery && user.getUserQuery.apiKey
                ? `xxxxxxxxxxxxxxxxxxxxxxxxxx${user.getUserQuery.apiKey}`
                : "You don't have any API Key. Click 'Generate API Key' to get one"
          }
        </div>

        {/* Copy to Clipboard button */}
        {isAPIKey && (
          <button
            className="shrink-0 w-10 h-10 flex justify-center items-center p-1 z-10 rounded-lg bg-slate-200"
            onClick={() => {
              copyToClipboard(currentApi);
            }}
          >
            <MdContentCopy
              className="cursor-pointer text-[#717171]  text-xl"
              style={{
                fontSize: "22px",
                color: "",
                backgroundColor: "transparent",
              }}
            />
          </button>
        )}
      </div>

      {/* Generate API Key button */}
      <button
        className="bg-edviron_black disabled: text-sm px-4 py-2 text-white mt-4 rounded-md ml-auto block"
        onClick={async () => {
          setOtpConfirmationBox(true);
          // const res = await API_KEY();
          // if (res != null) {
          //   SetCurrentApi(res.data.createApiKey.key);
          //   SetIsApiKey(true);
          //   set_user((prev: any) => {
          //     return {
          //       ...prev,
          //       getUserQuery: {
          //         ...prev.getUserQuery,
          //         apiKey: res.data.createApiKey.key.slice(-4),
          //       },
          //     };
          //   });
          //   setShowReminder(true); // Show the reminder after generating the API key
          // }
        }}
      >
        {/* {loading ? (
          <i className="fa-solid animate-spin fa-spinner"></i>
        ) : (
          ""
        )} */}
        Generate API Key
      </button>

      {/* Reminder */}
      {showReminder && (
        <p className="text-sm mt-2 text-gray-600">
          Save this API key for future use.
        </p>
      )}
    </div>
  );
}

export default ApiKey;
