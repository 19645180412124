import React, { useState } from "react";
import { _Table } from "../../../components/Table";
import Card from "../../../components/Card/Card";
import { useQuery } from "@apollo/client";
import { GET_DISPUTES } from "../../../Qurries";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { amountFormat } from "../../../utils/amountFormat";
function Disputes() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [dueAmounts, setDueAmounts] = useState({
    todayDueAmount: 0,
    tomorrowDueAmount: 0,
  });
  const { data: disputeData } = useQuery(GET_DISPUTES, {
    onCompleted(data) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const todayString = today.toISOString().split("T")[0];
      const tomorrowString = tomorrow.toISOString().split("T")[0];

      let todayDue = 0;
      let tomorrowDue = 0;

      data.getDisputes?.disputes.forEach((transaction: any) => {
        const dueDate = new Date(transaction.dispute_respond_by_date)
          .toISOString()
          .split("T")[0];

        if (dueDate === todayString) {
          todayDue += transaction.dispute_amount;
        } else if (dueDate === tomorrowString) {
          tomorrowDue += transaction.dispute_amount;
        }
      });

      setDueAmounts({
        todayDueAmount: todayDue,
        tomorrowDueAmount: tomorrowDue,
      });
    },
    variables: {
      page,
      limit,
    },
  });

  return (
    <div>
      <div className="grid grid-cols-3 gap-5 mb-4">
        <Card
          amount={amountFormat(dueAmounts.todayDueAmount)}
          date={"Due Today"}
          description={"Amount"}
        />
        <Card
          amount={amountFormat(dueAmounts.tomorrowDueAmount)}
          date={"Due Tommorrow"}
          description={"Amount"}
        />
        <Card
          amount={5000}
          date={"Insufficient Evidence"}
          description={"Amount"}
        />
      </div>
      <_Table
        heading="Disputes"
        exportBtn={true}
        data={[
          [
            "Created At",
            "Dispute ID",
            "Order ID",
            "Transaction ID",
            "Type",
            "Dispute Amt.",
            "Respond By",
            "Status",
            "Action",
          ],
          ...(disputeData?.getDisputes?.disputes.map((item: any) => [
            new Date(item.dispute_created_date).toLocaleString("hi"),
            item.dispute_id,
            item.collect_id,
            item._id,
            item.dispute_type,
            item.dispute_amount,
            new Date(item.dispute_respond_by_date).toLocaleString("hi"),
            item.dispute_status,
            "-",
          ]) || []),
        ]}
      />
    </div>
  );
}

export default Disputes;
