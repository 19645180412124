import React, { useState } from "react";
import { _Table } from "../../../../components/Table";
import RateChargeModal from "../../../../components/Modal/RateChargeModal";

import VendorStepForm from "./VendorStepForm";
import { useQuery } from "@apollo/client";
import { GET_VENDORS } from "../../../../Qurries";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import SchoolDetails from "./SchoolDetails";
import { useLocation } from "react-router-dom";
function Vendor() {
  const [showVender, setShowVender] = useState(false);
  let { state } = useLocation();

  const { data, loading } = useQuery(GET_VENDORS, {
    variables: {
      page: 1,
      limit: 100,
    },
  });
 
  return (
    <div className="w-full p-2">
      <RateChargeModal
        title="Vendor Information"
        open={showVender}
        setOpen={setShowVender}
      >
        <VendorStepForm setShowVender={setShowVender} />
      </RateChargeModal>

      {loading ? (
        <div className="min-h-[80vh] w-full flex justify-center items-center">
          <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
        </div>
      ) : (
        <_Table
          exportBtn={false}
          perPage={false}
          heading={"Institute details"}
          srNo={false}
          pagination={true}
          copyContent={[3]}
          searchBox={
            <div className="flex flex-col w-full">
              <SchoolDetails schoolId={state?.schoolId} />
              <div className="flex justify-between items-center">
                <h2 className="text-[#1B163B] font-semibold text-[18px]">
                  Vendor List
                </h2>
                <div>
                  <button
                    onClick={() => {
                      setShowVender(true);
                    }}
                    className=" bg-edviron_black  rounded-md text-white px-4 py-1.5"
                  >
                    Add Vendor
                  </button>
                </div>
              </div>
            </div>
          }
          data={[
            [
              "Sr.No",
              "Vendor Name",
              "Vendor ID",
              "Status",
              "Account holder Name",
              "Account Number",
              "IFSC Code",
              "Creation Date",
            ],
            ...data?.getVendors?.vendors
              ?.filter((school: any) => {
                return school.school_id === state?.schoolId;
              })
              .map((Vendor: any, index: number) => [
                <div className="cursor-pointer">{index + 1}</div>,

                <div className=" cursor-pointer">{Vendor.name}</div>,
                <div className=" cursor-pointer">{Vendor._id}</div>,
                <div

                // className={
                //   "cursor-pointer " +
                //   (Vendor.status.toLowerCase() === "active"
                //     ? " text-green-500"
                //     : Vendor.status.toLowerCase() === "inactive"
                //       ? "text-gray-500"
                //       : Vendor.status.toLowerCase() === "suspended"
                //         ? "text-red-500"
                //         : "")
                // }
                >
                  {Vendor?.status}
                </div>,
                <div className=" cursor-pointer">
                  {Vendor?.bank_details?.account_holder}
                </div>,
                <div className=" cursor-pointer">
                  {Vendor?.bank_details?.account_number}
                </div>,
                <div className=" cursor-pointer">
                  {Vendor?.bank_details?.ifsc}
                </div>,

                <div className=" cursor-pointer">
                  {new Date(Number(Vendor.createdAt)).toDateString()}
                </div>,
              ]),
          ]}
        />
      )}
    </div>
  );
}

export default Vendor;
