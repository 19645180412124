/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useRef, useState } from "react";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { _Table } from "../../../components/Table";
import { useQuery } from "@apollo/client";
import { GET_INVOICES, GET_MDR_REQUESTS, GET_SCHOOLS } from "../../../Qurries";
import RateChargeModal from "../../../components/Modal/RateChargeModal";
import Modal from "../../../components/Modal/Modal";
import SchoolRate, { validModes } from "./Tabs/CurrentCargeTab/SchoolRate";
import RequestTab from "./Tabs/RequestTab/RequestTab";
import { dashboardContext } from "../Dashboard";
import BulkRequest from "./BulkRequest";
import SingleSchoolRangeModal from "./Tabs/CurrentCargeTab/SingleSchoolRangeModal";
import PreviousRate from "./Tabs/RequestTab/Tabs/PreviousRate";
import RateTab from "./Tabs/CurrentCargeTab/Tabs/RateTab";
import Select from "react-select";
import BulkRateTab from "./Tabs/CurrentCargeTab/BulkRequest/BulkRateTab";
import FinalPaymentCalcution from "./FinalPaymentCalcution";
import InvoiceTab from "./Tabs/InvoiceTab/InvoiceTab";
import { GrCloudDownload } from "react-icons/gr";

function RatesAndCharges() {
  const { user, commissionTotalAmount } = useContext(dashboardContext);
  const [activeTab, setActiveTab] = useState(1);
  const [openRateChargeModal, setOpenRateChargeModal] = useState(false);
  const [openBulkMrdModal, setOpenBulkMrdModal] = useState(false);
  const [schoolID, setSchoolID] = useState(null);
  const [searchText, setSearchText] = useState<string>("");
  const [singleRequest, setSingleRequest] = useState<any>(null);
  const [requestId, setRequestId] = useState("");
  const [finalPaymentInfo, setfinalPaymentInfo] = useState<any>([]);
  const [invoiceDurations, setInvoiceDurations] = useState<any>([]);
  const { data: getMdrRequest } = useQuery(GET_MDR_REQUESTS);

  const { data: getInvoices } = useQuery(GET_INVOICES, {
    variables: {
      page: 1,
      limit: 100,
    },
  });

  const genratedInvoicesDates = getInvoices?.getInvoice?.map(
    (d: any) => d.invoice_date,
  );

  const [paymentRange, setPaymentRange] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const { data: all_schools } = useQuery(GET_SCHOOLS);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  const filterRequests = getMdrRequest?.getTrusteeMDRRequest.map(
    (request: any, index: number) => {
      if (request.school_id.length > 1) {
        const schools = request.school_id.map((s: any) => {
          return all_schools?.getSchoolQuery?.schools.filter(
            (school: any) => school.school_id === s,
          )[0];
        });
        return {
          schools,
          type: "bulk",
          ...request,
        };
      } else {
        const school = all_schools?.getSchoolQuery?.schools.filter(
          (school: any) => school.school_id === request.school_id[0],
        )[0];
        return {
          school,
          ...request,
        };
      }
    },
  );

  const queryArray =
    activeTab === 1 ? all_schools?.getSchoolQuery?.schools : filterRequests;
  const status = ["APPROVED", "REJECTED", "CANCELLED"];
  const mdr_requested_schools = filterRequests?.map((s: any) => {
    if (!status.includes(s?.status)) {
      const school_ids: any = [];
      s.school_id?.map((id: any) => school_ids.push(id));
      return school_ids;
    }
  });

  const mdr_not_initiated_schools = all_schools?.getSchoolQuery?.schools
    .filter((school: any) => {
      return !mdr_requested_schools?.flat()?.includes(school?.school_id);
    })
    .map((d: any) => {
      return {
        value: d.school_id,
        label: d.school_name,
        id: d.school_id,
      };
    });

  const SelectAllOption = {
    value: "*",
    label: "Select All",
  };
  const customStyles = {
    valueContainer: (provided: any) => ({
      ...provided,
      maxHeight: "80px",
      display: "flex",
      alignItems: "center",
      overflowY: "auto",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#6687FF33",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#6687FF33"
        : provided.backgroundColor,
      color: state.isSelected ? "#1B163B" : provided.color,
      ":hover": {
        backgroundColor: "lightgray",
      },
    }),

    control: (provided: any) => ({
      ...provided,
      minHeight: "40px",
    }),
  };
  const handleChange = (selected: any) => {
    if (isSelectAllSelected(selected)) {
      setSelectedOptions([]);
    } else if (
      selected.some(({ value }: any) => value === SelectAllOption.value)
    ) {
      setSelectedOptions(mdr_not_initiated_schools);
    } else {
      setSelectedOptions(selected);
    }
  };
  const SELECTED_SCHOOLS_IDS = selectedOptions?.map((d: any) => {
    return d.id;
  });

  const isSelectAllSelected = (selected: any) =>
    selected?.length === mdr_not_initiated_schools?.length + 1;

  const getOptions = (selected: any) =>
    isSelectAllSelected(selected)
      ? []
      : [SelectAllOption, ...mdr_not_initiated_schools];

  useEffect(() => {
    setSelectedOptions([]);
  }, [openBulkMrdModal]);

  const previewRange = finalPaymentInfo.length
    ? finalPaymentInfo?.map((d: any) => {
        return {
          ...d,
          range_charge: d.range_charge.map((d: any) => {
            return {
              commission: 0,
              base_charge: d.charge,
              base_charge_type: d.charge_type,
              ...d,
            };
          }),
        };
      })
    : user?.getUserQuery?.base_mdr?.platform_charges.map((d: any) => {
        return {
          ...d,
          range_charge: d.range_charge.map((d: any) => {
            return {
              commission: 0,
              base_charge: d.charge,
              base_charge_type: d.charge_type,
              ...d,
            };
          }),
        };
      });

  useEffect(() => {
    setfinalPaymentInfo([]);
  }, [openBulkMrdModal]);

  return (
    <div className="mt-2 w-full">
      <RateChargeModal
        title="Set Platform Charges"
        open={openBulkMrdModal}
        setOpen={setOpenBulkMrdModal}
        hasChanged={hasChanged}
      >
        <div className="px-8 py-4 h-40 bg-white">
          <p className=" text-edviron_black text-lg mb-4 font-medium">
            Update Bulk
          </p>
          {all_schools?.getSchoolQuery?.schools?.length && (
            <Select
              options={getOptions(mdr_not_initiated_schools)}
              value={selectedOptions}
              onChange={handleChange}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              styles={customStyles}
              placeholder="Select Schools.."
            />
          )}
        </div>
        <RateTab
          data={previewRange}
          PENDING_REQUEST={[]}
          isBulk={true}
          setOpen={setOpenBulkMrdModal}
          setPaymentRange={setPaymentRange}
          paymentRange={paymentRange}
          schoolId={SELECTED_SCHOOLS_IDS}
          setfinalPaymentInfo={setfinalPaymentInfo}
          finalPaymentInfo={finalPaymentInfo}
          hasChanged={hasChanged}
          setHasChanged={setHasChanged}
        />
      </RateChargeModal>
      <RateChargeModal
        open={openRateChargeModal}
        setOpen={setOpenRateChargeModal}
        hasChanged={hasChanged}
      >
        {activeTab === 1 && (
          <SchoolRate
            data={schoolID}
            requestList={filterRequests}
            setOpen={setOpenRateChargeModal}
            setfinalPaymentInfo={setfinalPaymentInfo}
            finalPaymentInfo={finalPaymentInfo}
            isBulk={false}
            hasChanged={hasChanged}
            setHasChanged={setHasChanged}
          />
        )}
        {activeTab === 2 && (
          <RequestTab
            requestId={requestId}
            data={singleRequest}
            setOpen={setOpenRateChargeModal}
          />
        )}
      </RateChargeModal>
      <RateChargeModal open={openInvoiceModal} setOpen={setOpenInvoiceModal}>
        <InvoiceTab
          setOpen={setOpenInvoiceModal}
          invoiceDates={genratedInvoicesDates}
        />
      </RateChargeModal>
      <h2 className="text-[#1B163B] text-[28px] font-[600]">Rates & Charges</h2>
      <div className="w-full -ml-2 mb-4 flex justify-between items-end">
        <div className="text-[#229635] font-[400] flex ">
          <span className="text-[48px] flex items-center">
            <LiaRupeeSignSolid />{" "}
            {commissionTotalAmount !== null ? (
              <span>
                {(Math.floor(commissionTotalAmount * 100) / 100).toLocaleString(
                  "hi-in",
                )}
              </span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {`(commission)`}
          </span>
        </div>
        <div className="">
          <div className="text-[14px] font-[400] text-right m-0">
            settlement cycle
          </div>
          <div className="text-[20px] font-[600] text-[#6687FF] m-0 mt-0">
            5th of every month
          </div>
        </div>
      </div>
      {queryArray ? (
        <_Table
          heading={"History"}
          pagination={true}
          filter={activeTab}
          searchBox={
            <div className="flex flex-col gap-y-4 justify-between w-full">
              <div className="flex justify-between items-center">
                <div className=" w-full flex gap-x-2">
                  <button
                    onClick={() => setActiveTab(1)}
                    className={
                      activeTab === 1
                        ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                        : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                    }
                  >
                    Current Charges
                  </button>
                  <button
                    onClick={() => setActiveTab(2)}
                    className={
                      activeTab === 2
                        ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                        : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                    }
                  >
                    Request(s) Sent
                  </button>
                  <button
                    onClick={() => setActiveTab(3)}
                    className={
                      activeTab === 3
                        ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                        : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                    }
                  >
                    Invoice
                  </button>
                </div>
                <div className="flex items-center flex-none gap-x-3">
                  {activeTab === 3 && (
                    <button
                      onClick={() => {
                        setOpenInvoiceModal(true);
                      }}
                      className="bg-edviron_black text-white w-full max-w-fit text-[14px] font-medium px-6 py-2 rounded-md"
                    >
                      Add Invoice
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setOpenBulkMrdModal(true);
                    }}
                    className="bg-edviron_black text-white w-full max-w-fit text-[14px] font-medium px-6 py-2 rounded-md"
                  >
                    Updates Rates
                  </button>
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-full">
                  <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                    <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                    {activeTab === 1 ? (
                      <input
                        className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                        onFocus={(e) =>
                          (e.target.style.borderColor = "transparent")
                        }
                        type="text"
                        placeholder=" Search(Institute Name,Order ID...)"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    ) : (
                      <input
                        className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                        onFocus={(e) =>
                          (e.target.style.borderColor = "transparent")
                        }
                        type="text"
                        placeholder={
                          activeTab === 3
                            ? " Search (Invoice...) "
                            : " Search (Request Status...)"
                        }
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* <div>{all filters}</div> */}
              </div>
            </div>
          }
          data={
            activeTab === 1
              ? [
                  [
                    "S.No.",
                    "School Name",
                    "KYC Status",
                    "Mode Active",
                    "Commission Recieved",
                    "Last Updated",
                  ],
                  ...queryArray
                    .filter((d: any) => {
                      const arr = [
                        d.school_name,
                        d.email,
                        d.school_id,
                        d.pg_key,
                      ].join(",");
                      return arr
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                    })
                    .map((school: any, index: number) => [
                      <div className="cursor-pointer">{index + 1}</div>,
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSchoolID(school?.school_id);
                          setOpenRateChargeModal(true);
                        }}
                      >
                        {school?.school_name}
                      </div>,
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSchoolID(school?.school_id);
                          setOpenRateChargeModal(true);
                        }}
                      >
                        {school?.merchantStatus}
                      </div>,
                      <div
                        onClick={() => {
                          setSchoolID(school?.school_id);
                          setOpenRateChargeModal(true);
                        }}
                        className="pl-4 cursor-pointer"
                      >
                        {
                          validModes.filter((d: any) => {
                            if (!school?.disabled_modes.includes(d)) return d;
                          }).length
                        }
                      </div>,
                      <div
                        onClick={() => {
                          setSchoolID(school?.school_id);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {" "}
                        NA {/*requestUpdatedAt*/}
                      </div>,
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSchoolID(school?.school_id);
                          setOpenRateChargeModal(true);
                        }}
                      >
                        {new Date(school?.updatedAt).toLocaleString("hi-in")}
                      </div>,
                    ]),
                ]
              : activeTab === 2
                ? [
                    [
                      "S.No",
                      "School Name",
                      "Status",
                      "Initiated At",
                      "Last Updated",
                      "Comments",
                    ],
                    ...filterRequests
                      .sort(
                        (a: any, b: any) =>
                          new Date(Number(b.createdAt)).getTime() -
                          new Date(Number(a.createdAt)).getTime(),
                      )
                      .map((d: any, index: number) => [
                        <div>{index + 1}</div>,
                        <div
                          onClick={() => {
                            setSingleRequest(d);
                            setOpenRateChargeModal(true);
                            setRequestId(d?._id);
                          }}
                          className="cursor-pointer"
                        >
                          {d.school ? d.school.school_name : "Bulk"}
                        </div>,
                        <div
                          onClick={() => {
                            setSingleRequest(d);
                            setOpenRateChargeModal(true);
                            setRequestId(d?._id);
                          }}
                          className="cursor-pointer"
                        >
                          {d.status}
                        </div>,
                        <div
                          onClick={() => {
                            setSingleRequest(d);
                            setOpenRateChargeModal(true);
                            setRequestId(d?._id);
                          }}
                          className="cursor-pointer"
                        >
                          {new Date(Number(d.createdAt)).toLocaleDateString(
                            "hi",
                          )}
                        </div>,
                        <div
                          onClick={() => {
                            setSingleRequest(d);
                            setOpenRateChargeModal(true);
                            setRequestId(d?._id);
                          }}
                          className="cursor-pointer"
                        >
                          {new Date(Number(d.updatedAt)).toLocaleDateString(
                            "hi",
                          )}
                        </div>,
                        <div
                          onClick={() => {
                            setSingleRequest(d);
                            setOpenRateChargeModal(true);
                            setRequestId(d?._id);
                          }}
                          className="cursor-pointer"
                        >
                          {d.description}
                        </div>,
                      ]),
                  ]
                : [
                    [
                      "S.No",
                      "Invoice No",
                      "Created Date",
                      "Amount (incl. Tax ) File",
                      "Status",
                      "Action",
                    ],
                    ...getInvoices?.getInvoice
                      .filter((d: any) => {
                        const arr = [d.invoice_no, d.invoice_status].join(",");
                        return arr
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      })
                      .map((d: any, index: number) => [
                        <div>{index + 1}</div>,
                        <div>{d?.invoice_no}</div>,
                        <div>
                          {new Date(Number(d?.createdAt)).toDateString()}
                        </div>,
                        <div>₹{d?.invoice_details?.total}</div>,
                        <div>{d?.invoice_status}</div>,
                        <div>
                          <a
                            target="_blank"
                            href={d.invoice_url}
                            download="proposed_file_name"
                          >
                            <GrCloudDownload className="text-2xl" />
                          </a>
                        </div>,
                      ]),
                  ]
          }
        />
      ) : (
        "No Data"
      )}
    </div>
  );
}

export default RatesAndCharges;
