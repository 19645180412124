import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PaymentSmallCard } from "../Transaction/TransactionReceipt";
import { amountFormat } from "../../../utils/amountFormat";
import { _Table } from "../../../components/Table";
import { getVendorAmount } from "../Transaction/Transaction";
import { calculateNegitiveAmount } from "../../../utils/calculateNegitiveAmount";
import { HiMiniXMark } from "react-icons/hi2";

function ReceiptOfReconciliation() {
  const location = useLocation();
  const receivedData = location.state;

  const vendors: { [key: string]: any[] } =
    receivedData?.vendors_transactions?.reduce((acc: any, tx: any) => {
      const { vendorName } = tx;
      if (!acc[vendorName]) {
        acc[vendorName] = [];
      }
      acc[vendorName].push(tx);
      return acc;
    }, {});

  return (
    <div className="min-h-[80vh] rounded-lg mt-4 bg-[#F6F8FA] p-4">
      <h3 className="flex items-center text-lg font-semibold text-[#1B163B]">
        <Link to="/payments">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className=" h-6 mr-2 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </Link>
        Go Back
      </h3>
      <div id="receipt" className="p-4">
        <p className=" my-4 text-base text-[#767676] font-medium">
          Merchant details
        </p>

        <div className="overflow-hidden border rounded-lg">
          <div className=" bg-[#6687FF1A] border-b border-[#A9B2CF] flex items-center px-8 py-4">
            <div className="grid grid-cols-4 gap-5">
              <p className=" text-[#1B163B] font-semibold text-sm">
                Merchant Name
                <span className=" font-normal ml-2">
                  {receivedData.school_name}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Settlement From :
                <span className=" font-normal ml-2">
                  {new Date(receivedData.fromDate).toLocaleString("hi")}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Till :
                <span className=" font-normal ml-2">
                  {new Date(receivedData.tillDate).toLocaleString("hi")}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Total Merchant Order Amount:
                <span className=" font-normal ml-2">
                  {" "}
                  {amountFormat(receivedData?.totaltransactionAmount)}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Settlement Amount:
                <span className=" font-normal ml-2">
                  {" "}
                  {amountFormat(receivedData?.settlementAmount)}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Settlement UTR:
                <span className=" font-normal ml-2">
                  {" "}
                  {receivedData.utrNumber ? receivedData.utrNumber : "NA"}
                </span>
              </p>

              <div className=" text-[#1B163B] flex items-center font-semibold text-sm">
                Settled On:
                <span className=" font-normal ml-2">
                  {new Date(receivedData.settlementDate).toLocaleString("hi")}
                </span>
              </div>
              <div className=" text-[#1B163B] flex items-center font-semibold text-sm">
                Settlement Mismatch:
                <span
                  className={
                    " font-bold ml-2 " +
                    (receivedData.merchantAdjustment !== 0
                      ? " text-red-500"
                      : "text-green-500")
                  }
                >
                  {receivedData.merchantAdjustment !== 0 ? "Yes" : "No"}
                </span>
              </div>
              <div className=" text-[#1B163B] flex items-center font-semibold text-sm">
                Remarks: &nbsp;
                <span>
                  {receivedData.remarks ? receivedData.remarks : " NA"}
                </span>
              </div>
            </div>
          </div>
          <div>
            <_Table
              pagination={true}
              boxPadding={" p-0 pt-4"}
              copyRight={false}
              perPage={true}
              minHeight={"h-auto"}
              data={[
                [
                  "Sr.No.",
                  "Order ID",
                  "Order Amount",
                  "Merchant Order Amount",
                  "UTR",
                  "Settlement Date",
                  "Payment Date",
                  "In Settlement Period",
                  "Refund Amount",
                  "Refund Proceess Date",
                  "Chargeback Amount",
                  "Chargeback Proceess Date",
                ],
                ...([
                  ...receivedData?.duration_transactions,
                  ...receivedData?.refunds,
                  ...receivedData?.chargeBacks,
                ]
                  .sort(
                    (a: any, b: any) =>
                      (b.refund_amount > 0 ? 1 : 0) -
                      (a.refund_amount > 0 ? 1 : 0),
                  )
                  .map((item: any, index: number) => [
                    <span>{index + 1}</span>,
                    <span>
                      {item.custom_order_id
                        ? item.custom_order_id
                        : item.collect_id}
                    </span>,
                    <span>{amountFormat(item.order_amount)}</span>,
                    <span>
                      {item.vendors_info?.length > 0
                        ? amountFormat(
                            getVendorAmount({
                              array: item?.vendors_info,
                              orderAmount: item?.order_amount,
                            }),
                          )
                        : amountFormat(item.order_amount)}
                    </span>,
                    <span>
                      {receivedData.utrNumber ? receivedData.utrNumber : "NA"}
                    </span>,
                    <span>
                      {new Date(receivedData.settlementDate).toLocaleString(
                        "hi",
                      )}
                    </span>,
                    <span>
                      {new Date(item.payment_time).toLocaleString("hi")}
                    </span>,
                    <span>
                      {item.inSettlements === false
                        ? item.inSettlements === false
                          ? "No"
                          : "Yes"
                        : "Yes"}
                    </span>,
                    <span
                      className={
                        " " +
                        (item.refund_amount > 0 &&
                        item.__typename === "ReconRefundInfo" &&
                        item.isChargeBack !== true
                          ? "text-orange-500 font-bold"
                          : "text-black")
                      }
                    >
                      {item.refund_amount &&
                      item.__typename === "ReconRefundInfo" &&
                      item.isChargeBack !== true
                        ? amountFormat(item.refund_amount)
                        : "0"}
                    </span>,
                    <span>
                      {item.__typename === "ReconRefundInfo" &&
                      item.isChargeBack !== true
                        ? new Date(Number(item.updatedAt)).toLocaleString("hi")
                        : "-"}
                    </span>,
                    <span
                      className={
                        " " +
                        (item.refund_amount > 0 && item.isChargeBack === true
                          ? "text-orange-500 font-bold"
                          : "text-black")
                      }
                    >
                      {item.refund_amount && item.isChargeBack === true
                        ? amountFormat(item.refund_amount)
                        : "0"}
                    </span>,
                    <span>
                      {item.isChargeBack === true
                        ? new Date(item.updatedAt).toLocaleString("hi")
                        : "-"}
                    </span>,
                  ]) || []),
              ]}
              footer={
                <div className="border-t mx-4 py-4 border-gray-200 flex justify-end">
                  <div className="space-y-1 flex flex-col max-w-[250px] w-full">
                    <div className="flex w-full  justify-between items-center">
                      <p className="text-xs">Total Merchant Amount:</p>
                      <p
                        className={
                          "font-medium text-sm " +
                          (amountFormat(
                            receivedData?.totaltransactionAmount -
                              receivedData?.splitTransactionAmount,
                          ).includes("-")
                            ? "text-red-500"
                            : "text-black")
                        }
                      >
                        {amountFormat(
                          receivedData?.totaltransactionAmount -
                            receivedData?.splitTransactionAmount,
                        )}
                      </p>
                    </div>
                    <div className="flex w-full  justify-between items-center">
                      <p className="text-xs">Refund Amount:</p>
                      <p
                        className={
                          "font-medium text-sm  " +
                          (receivedData?.refundSum > 0
                            ? "text-red-500"
                            : "text-black")
                        }
                      >
                        -{amountFormat(receivedData?.refundSum)}
                      </p>
                    </div>
                    <div className="flex w-full  justify-between items-center">
                      <p className="text-xs">Chargeback:</p>
                      <p className={"font-medium text-sm text-black"}>
                        -{amountFormat(0)}
                      </p>
                    </div>
                    <div className="flex w-full pb-1 justify-between items-center">
                      <p className="text-xs">Other Adjustment:</p>
                      <p
                        className={
                          "font-medium text-sm " +
                          (amountFormat(
                            receivedData?.merchantOtherAdjustment,
                          ).includes("-")
                            ? "text-red-500"
                            : "text-black")
                        }
                      >
                        {amountFormat(receivedData?.merchantOtherAdjustment)}
                      </p>
                    </div>
                    <div className="flex w-full justify-between items-center">
                      <p className="text-xs">PG Charges & Taxes:</p>

                      <p
                        className={
                          "font-medium text-sm " +
                          (receivedData?.payment_service_tax +
                            receivedData?.payment_service_charge >
                          0
                            ? "text-red-500"
                            : "text-black")
                        }
                      >
                        -
                        {amountFormat(
                          receivedData?.payment_service_tax +
                            receivedData?.payment_service_charge,
                        )}
                      </p>
                    </div>
                    <p className="text-[8px]  border-b pb-1 text-right">
                      {amountFormat(
                        receivedData?.payment_service_tax +
                          receivedData?.payment_service_charge,
                      )}
                      {`(${receivedData?.payment_service_charge === null ? 0 : receivedData?.payment_service_charge} + ${receivedData?.payment_service_tax === null ? 0 : receivedData?.payment_service_tax})`}
                    </p>
                    <div className="flex w-full  justify-between items-center">
                      <p className="font-semibold text-sm text-black">
                        Final Settlement:
                      </p>
                      <p className="text-green-500 font-semibold text-sm">
                        {amountFormat(receivedData?.settlementAmount)}
                      </p>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
        {Object.entries(vendors).length > 0 && (
          <p className=" my-4 text-base text-[#767676] font-medium">
            Vendor Details
          </p>
        )}
        <div className="space-y-4">
          {Object.entries(vendors).map((vendor, index) => (
            <>
              <div className="overflow-hidden border rounded-lg">
                <div className=" bg-[#6687FF1A] border-b border-[#A9B2CF] flex items-center px-8 py-4">
                  <div className="grid grid-cols-4 gap-5">
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Vendor Name
                      <span className=" font-normal ml-2">{vendor[0]}</span>
                    </p>
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Settlement From :
                      <span className=" font-normal ml-2">
                        {new Date(receivedData.fromDate).toLocaleString("hi")}
                      </span>
                    </p>
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Till :
                      <span className=" font-normal ml-2">
                        {new Date(receivedData.tillDate).toLocaleString("hi")}
                      </span>
                    </p>
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Vendor Order Amount:
                      <span className=" font-normal ml-2">
                        {" "}
                        {amountFormat(receivedData?.totaltransactionAmount)}
                      </span>
                    </p>
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Settlement Amount:
                      <span className=" font-normal ml-2">
                        {" "}
                        {amountFormat(receivedData?.settlementAmount)}
                      </span>
                    </p>
                    <p className=" text-[#1B163B] font-semibold text-sm">
                      Settlement UTR:
                      <span className=" font-normal ml-2">
                        {" "}
                        {receivedData.utrNumber ? receivedData.utrNumber : "NA"}
                      </span>
                    </p>

                    <div className=" text-[#1B163B] flex items-center font-semibold text-sm">
                      Settled On:
                      <span className=" font-normal ml-2">
                        {new Date(receivedData.settlementDate).toLocaleString(
                          "hi",
                        )}
                      </span>
                    </div>
                    <div className=" text-[#1B163B] flex items-center font-semibold text-sm">
                      Settlement Mismatch:
                      <span
                        className={
                          " font-normal ml-2" +
                          (receivedData.merchantAdjustment !== 0
                            ? " text-red-500"
                            : "text-green-500")
                        }
                      >
                        {receivedData.merchantAdjustment !== 0 ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <_Table
                    pagination={true}
                    boxPadding={" p-0"}
                    copyRight={false}
                    minHeight={"h-auto"}
                    data={[
                      [
                        "Sr.No.",
                        "Order ID",
                        "Order Amount",
                        "Vendor Order Amount",
                        "UTR",
                        "Settlement Date",
                        "Payment Date",
                        "In Settlement Period",
                        "Refund Amount",
                        "Refund Proceess Date",
                      ],
                      ...([...vendor[1]].map((item: any, index: number) => [
                        <span>{index + 1}</span>,
                        <span>
                          {item.custom_order_id
                            ? item.custom_order_id
                            : item.collect_id}
                        </span>,
                        <span>{amountFormat(item.order_amount)}</span>,
                        <span>
                          {item.vendors_info?.length > 0
                            ? amountFormat(
                                getVendorAmount({
                                  array: item?.vendors_info,
                                  orderAmount: item?.order_amount,
                                }),
                              )
                            : "NA"}
                        </span>,
                        <span>
                          {receivedData.utrNumber
                            ? receivedData.utrNumber
                            : "NA"}
                        </span>,
                        <span>
                          {new Date(receivedData.settlementDate).toLocaleString(
                            "hi",
                          )}
                        </span>,
                        <span>
                          {/* {new Date(item.payment_time).toLocaleString("hi")} */}
                        </span>,
                        <span>
                          {/* {new Date(item.payment_time).toLocaleString("hi")} */}
                        </span>,
                        <span>
                          {/* {item.refund_amount
                            ? amountFormat(item.refund_amount)
                            : "0"} */}
                        </span>,
                        <span>
                          {/* {item.__typename === "ReconRefundInfo"
                            ? new Date(Number(item.updatedAt)).toLocaleString(
                                "hi"
                              )
                            : "-"} */}
                        </span>,
                      ]) || []),
                    ]}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <p className=" m-4 text-base text-[#767676] font-medium">
          Mismatched Orders
        </p>
        <_Table
          pagination={true}
          boxPadding={" p-0"}
          copyRight={false}
          minHeight={"h-auto"}
          data={[
            [
              "Sr.No.",
              "Order ID",
              "Order Amount",
              "Payment Date",
              "In Settlement Period",
            ],
            ...([
              ...receivedData?.extraInTransaction,
              ...receivedData?.extraInSettlement,
            ].map((item: any, index: number) => [
              <span>{index + 1}</span>,
              <span>{item.custom_id ? item.custom_id : item.collect_id}</span>,
              <span>{amountFormat(item.order_amount)}</span>,

              <span>{new Date(item.payment_time).toLocaleString("hi")}</span>,
              <span>
                {item.inSettlements === false
                  ? item.inSettlements === false
                    ? "No"
                    : "Yes"
                  : "Yes"}
              </span>,
            ]) || []),
          ]}
          footer={
            ![
              ...receivedData?.extraInTransaction,
              ...receivedData?.extraInSettlement,
            ].length && (
              <span className="ml-4">
                It's a relief. There are no such transactions for this
                settlement
              </span>
            )
          }
        />
      </div>
    </div>
  );
}

export default ReceiptOfReconciliation;
